
<template>
  <div class="content" id="dashboard">
    <appSideNavigation></appSideNavigation>
    <div class="main-content-container">
      <div class="site-header">
        <h3>{{name}}</h3>
      </div>
      <div class="site-content"></div>
    </div>
  </div>
</template>

<script>
import SideNavigation from '../components/SideNavigation.vue'
import Util from '../mixins/util'

export default {
  data () {
    return {
      name: 'Charts'
    }
  },
  mounted: function () {
    this.navigateDependingOnSession()
  },
  components: {
    appSideNavigation: SideNavigation
  },
  mixins: [Util]
}

</script>

<style scoped>

</style>